

:root {
    --gris2: #F3F3F3;
    --gris4: #DBDBDB;
    --gris6: #676767;
    --rojoPrimario: #891928;
    --negroTextosShade: #000A12
}

html {
    scroll-behavior: smooth
}

a,
article,
audio,
b,
big,
blockquote,
body,
caption,
center,
code,
dd,
del,
details,
div,
dl,
dt,
em,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
tr,
tt,
u,
ul,
var,
video {
    border: 0;
    margin: 0;
    padding: 0;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block
}

ol,
ul {
    list-style: none
}

*,
:after,
:before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.container {
    width: 100%;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-left: 24px;
    padding-right: 24px
}

.container:after,
.container:before {
    content: " ";
    display: table
}

.container:after {
    clear: both
}

.row {
    margin-left: -15px;
    margin-right: -15px
}

.row:after,
.row:before {
    content: " ";
    display: table
}

.row:after {
    clear: both
}

.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px
}

.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
    float: left
}

.col-xs-1 {
    width: 8.333%
}

.col-xs-2 {
    width: 16.666%
}

.col-xs-3 {
    width: 25%
}

.col-xs-4 {
    width: 33.333%
}

.col-xs-5 {
    width: 41.666%
}

.col-xs-6 {
    width: 50%
}

.col-xs-7 {
    width: 58.333%
}

.col-xs-8 {
    width: 66.666%
}

.col-xs-9 {
    width: 75%
}

.col-xs-10 {
    width: 83.333%
}

.col-xs-11 {
    width: 91.666%
}

.col-xs-12 {
    width: 100%
}

a {
    outline: 0;
    color: #007FAA;
    text-decoration: none
}

a:link {
    color: #525252;
    text-decoration: none
}

a:focus {
    outline: 0
}

a.button {
    color: #FFF
}

a.link {
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    text-decoration: none
}

a.link--primary-red {
    color: #891928
}

body {
    margin: 0;
    padding: 0;
    line-height: 1;
    color: #283238;
    font-size: 100%;
    font-weight: 400;
    line-height: 24px;
    counter-reset: my-counter;
    font-family: Roboto, sans-serif !important
}

.roboto-bold,
.roboto-extrabold,
.roboto-light,
.roboto-medium,
.roboto-regular {
    font-weight: 300;
    font-family: Roboto, sans-serif
}

.roboto-regular {
    font-weight: 400
}

.roboto-medium {
    font-weight: 500
}

.roboto-bold {
    font-weight: 700
}

.roboto-extrabold {
    font-weight: 900
}

.animation-delay {
    -webkit-transition: all 0.3s;
    transition: all 0.3s
}

.py-1 {
    padding-top: 8px;
    padding-bottom: 8px
}

.mb-1 {
    margin-bottom: 8px
}

.mb-2 {
    margin-bottom: 16px
}

.mr-1 {
    margin-right: 8px
}

.ml-1 {
    margin-left: 8px
}

.text-center {
    text-align: center
}

.vcenter {
    float: none;
    display: inline-block;
    vertical-align: middle
}

.pointer {
    cursor: pointer
}

body.menu-fixed-home {
    padding-top: 95.4667px
}

.header.sticky {
    left: 0;
    position: fixed;
    top: 0;
    width: 100%
}

.section-padding {
    padding: 60px 0
}

.image__container {
    z-index: 1;
    float: left;
    width: 100%;
    position: relative
}

.img-responsive {
    height: auto;
    display: block;
    margin: 0 auto;
    max-width: 100%
}

.img-responsive--desktop {
    display: none
}

.header {
    top: 0;
    z-index: 36;
    position: sticky;
    background: #FFF !important;
    position: -webkit-sticky;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1)
}

.header .container {
    padding: 16px 24px
}

.header__logo {
    width: 100%
}

.header__logo .img-responsive {
    max-width: 200px
}

.header__logo .img-responsive--desktop {
    display: none
}

.header__menu,
.header__menu-right,
.header__phone {
    display: none
}

.hero {
    position: relative;
    z-index: 1;
    background: #891928;
    background-size: cover !important;
    background-position: center !important;
    color: #fff;
    height: 560px;
    padding-top: 130px
}

.hero__title {
    font-size: 2em;
    line-height: 48px;
    /* padding-bottom: 24px */
}

.hero__description {
    font-size: 1.125em;
    line-height: 27px;
    padding-bottom: 24px
}

.hero--auto {
    height: auto;
    padding-bottom: 130px
}

.container--hero {
    position: relative;
    z-index: 3
}

.hamburger {
    top: -12px;
    left: 10px;
    width: 20px;
    z-index: 12;
    cursor: pointer;
    margin-top: 10px;
    position: absolute;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.menu__mobile {
    top: 0;
    left: -100%;
    z-index: 50;
    width: 100%;
    padding: 16px;
    min-height: 100%;
    background: #FFF;
    position: absolute;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1)
}

.nav-mobile {
    background: #891928
}

.nav-mobile .container {
    padding: 0
}

.nav-mobile__menu {
    text-align: center
}

.nav-mobile__menu li {
    float: left;
    width: calc(100% / 3)
}

.nav-mobile__menu li:hover {
    background: #560000
}

.nav-mobile__menu li:not(:first-child) {
    border-left: 0.5px solid rgba(255, 255, 255, 0.2)
}

.nav-mobile__menu a,
.nav-mobile__menu a:hover,
.nav-mobile__menu a:visited {
    color: #fff;
    display: inline-block;
    width: 100%;
    padding: 8px 0
}

.menu__mobile .arrow--menu {
    display: none
}

.menu__mobile.is-active {
    height: 100vh;
    left: 0;
    overflow-y: scroll;
    position: fixed
}

.menu__mobile__header {
    float: left;
    width: 100%;
    padding-bottom: 24px
}

.menu__mobile .hamburger--close {
    top: 20px;
    left: 24px
}

.menu__mobile__close {
    float: left
}

.menu__mobile__search {
    float: right
}

.menu__mobile__content {
    float: left;
    width: 100%;
    font-size: 0.875em;
    line-height: 21px;
    margin-bottom: 16px
}

.menu__mobile__list li {
    float: left;
    padding: 0 8px 16px 0;
    width: 50%
}

.menu__mobile__list .sub-menu {
    margin-top: 16px
}

.menu__mobile__list .sub-menu>li {
    border-left: 1px solid #891928;
    padding-left: 16px;
    font-weight: 300;
    width: 100%
}

.menu__mobile__list .sub-menu>li:last-child {
    padding-bottom: 0
}

.menu__mobile__list a,
.menu__mobile__list a:hover {
    color: #515b62
}

.menu__mobile__list a[target=_blank] {
    position: relative;
    z-index: 1
}

/* .menu__mobile__list a[target=_blank]:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: -20px;
    z-index: 2;
    background: url(../images/icons/arrow-target-blank.svg) no-repeat;
    height: 16px;
    width: 16px
} */

.menu__mobile__footer {
    float: left;
    width: 100%;
    font-size: 0.875em;
    line-height: 21px;
    margin-bottom: 16px;
    border-bottom: 1px solid #dbdbdb
}

.menu__mobile__footer li {
    border-top: 1px solid #dbdbdb;
    padding: 12px 0
}

.menu__mobile__footer li a {
    color: #891928
}

.menu__mobile__footer li .arrow {
    float: right
}

.nav-landing {
    background: #fafafa;
    padding-top: 10px
}

.nav-landing a {
    position: relative;
    z-index: 1;
    display: inline-block;
    color: #515b62;
    padding: 0 16px 13px
}

.nav-landing a:after {
    content: '';
    height: 3px;
    background: 0 0;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    width: 100%
}

.nav-landing a:hover {
    color: #515b62
}

.nav-landing a:hover:after {
    background: #d73f3c
}

.nav-landing__menu {
    text-align: center;
    overflow: auto;
    white-space: nowrap;
    -ms-overflow-style: none;
    scrollbar-width: none
}

.nav-landing__menu li {
    display: inline-block
}

.nav-landing__menu li:not(:first-child) {
    padding-left: 30px
}

.nav-landing__menu li.current-cat a:after,
.nav-landing__menu li.current_page_item a:after {
    background: #d73f3c
}

.nav-landing__menu::-webkit-scrollbar {
    display: none
}

.button {
    position: relative;
    z-index: 1;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border: 1px solid transparent;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    line-height: 1;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    text-align: center;
    padding: 8px 24px
}

.button--primary-red {
    background: #891928
}

.button--secondary-red {
    background: #d73f3c
}

.button--lg,
.button--sm {
    font-size: 14px
}

.button--lg {
    line-height: 21px;
    min-width: 164px;
    padding: 13px 16px;
    width: 100%
}

.button--sm {
    line-height: 16px;
    min-width: 128px;
    padding: 12px 24px;
    width: auto
}

.button--full {
    padding: 13px 16px;
    width: 100%
}

.layer {
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    position: absolute;
    background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.6)), to(transparent));
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.6), transparent);
}

.icon {
    display: inline-block;
    height: 32px;
    padding-top: 12px;
    vertical-align: middle;
    width: 32px
}

.icon--search-movil {
    top: 0;
    right: 0;
    z-index: 3;
    cursor: pointer;
    position: absolute
}

.valign,
.vertical-align {
    vertical-align: middle
}

.floating-menu .is-active .icon--menu-fab:before {
    content: "\274c"
}

.icon--menu-fab:before {
    content: "\1f3a7";
    font-size: 28px;
    color: #fff;
    position: absolute;
    top: 16px;
    right: 16px
}

.icon--xs {
    height: 24px;
    width: 24px
}

.icon--lg {
    height: 28px;
    width: 28px
}

.icon--menu:before {
    content: "\1f7f0";
    font-size: 24px;
    color: #283238
}

.icon--menu-close:before {
    content: "\274c";
    font-size: 24px;
    color: #283238
}

.icon--menu-close:hover:before,
.icon--menu:hover:before,
.icon--search:hover:before {
    color: #891928
}

.icon--search:before {
    content: "\1f50d";
    font-size: 24px;
    color: #283238
}

.icon--question-hover:before,
.icon--question:before {
    content: "\2753";
    font-size: 24px;
    color: #283238
}

.icon--question-hover:before {
    color: #891928
}

.icon--facebook:before {
    content: "\f09a";
    font-size: 28px;
    color: #989898
}

.icon--twitter:before {
    content: "\1f426";
    font-size: 28px;
    color: #989898
}

.icon--youtube:before {
    content: "\1f53a";
    font-size: 28px;
    color: #989898
}

.icon--ig:before {
    content: "\1f4f8";
    font-size: 28px;
    color: #989898
}

.icon--ayuda-red:before {
    content: "\2709";
    font-size: 28px;
    color: #891928
}

.icon--promotions:before {
    content: "\2b50";
    font-size: 24px;
    color: #891928
}

.icon--blog:before {
    content: "\1f310";
    font-size: 24px;
    color: #891928
}

.icon--pin:before {
    content: "\1f4cd";
    font-size: 24px;
    color: #891928
}

.icon--inversion--social:before {
    content: "\1faf6";
    font-size: 24px;
    color: #891928
}

.arrow {
    display: inline-block;
    vertical-align: middle;
    background-size: contain !important;
    background-position: center !important
}

.arrow--menu:before {
    color: #891928;
    content: "\2b07";
    font-size: 20px;
    margin-left: 3px
}

.arrow--menu.arrow--lg:before {
    top: -4px;
    font-size: 24px;
    margin-right: 4px;
    position: relative
}

/* .arrow--white {
    background: url(../images/icons/next-white.svg) no-repeat
} */

.vertical-centering-box {
    display: table;
    height: 100%;
    width: 100%
}

.vertical-centering-box__centering {
    display: table-cell;
    vertical-align: middle
}

.owl-carousel>* {
    display: none
}

.owl-carousel>:first-child,
.owl-loaded>* {
    display: block
}

.promotions__slider-item {
    background-size: cover !important;
    background-position: top center !important;
    position: relative;
    z-index: 1;
    color: #fff;
    height: 600px
}

.promotions .container {
    position: relative;
    text-align: center;
    z-index: 4
}

.promotions .container--txt-black {
    color: #283238
}

.promotions__title {
    font-size: 2em;
    line-height: 48px;
    padding-bottom: 24px
}

.promotions__description {
    font-size: 1.125em;
    line-height: 27px;
    padding-bottom: 24px
}

.promotions .button--lg-promotions {
    width: 210px
}

.promotions__caption {
    position: absolute;
    bottom: 72px;
    left: 0;
    z-index: 5;
    font-size: 0.75em;
    line-height: 18px;
    padding: 0 24px
}

.accordion {
    outline: 0;
    width: 100%;
    cursor: pointer;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s
}

.accordion:after {
    width: 24px;
    float: right;
    height: 24px;
    content: "\2795";
    font-size: 24px;
    color: #989898
}

.accordion.is-active:after {
    content: "\2796"
}

.panel {
    display: none;
    transition: max-height 0.2s ease-out;
    -webkit-transition: max-height 0.2s ease-out;
    -moz-transition: max-height 0.2s ease-out;
    -o-transition: max-height 0.2s ease-out
}

.search-bar {
    left: 0;
    top: 55px;
    z-index: 10;
    width: 100%;
    display: none;
    padding: 24px 0;
    position: fixed;
    background: #fff;
    border-top: 1px solid #dbdbdb
}

.search-bar .container {
    position: relative;
    z-index: 1
}

.search-bar__close {
    position: absolute;
    top: -1em;
    right: 0;
    z-index: 2
}

.search-bar.is-active {
    display: block !important
}

.search-bar__form {
    text-align: center
}

.search-bar__form .form__group {
    width: 100%
}

.floating-menu {
    z-index: 45;
    right: 16px;
    bottom: 16px;
    position: fixed !important
}

.floating-menu a {
    width: 60px;
    height: 60px;
    display: block;
    text-align: center;
    background: #000A12;
    border-radius: 100%
}

.floating-menu #menu-fab {
    right: 0;
    bottom: 0;
    z-index: 2;
    position: absolute
}

.floating-menu__list {
    margin: 0 0 84px;
    display: none
}

h2 {
    font-family: Roboto, sans-serif;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    padding-bottom: 16px
}

.section-title {
    font-family: Roboto, sans-serif;
    font-size: 1.5em;
    line-height: 36px;
    padding-bottom: 8px;
    text-align: center
}

.steps__description {
    color: #989898;
    font-size: 0.875em;
    line-height: 21px;
    text-align: center;
    padding-bottom: 48px
}

.steps__list {
    float: left;
    width: 100%;
    padding: 0 16px 0 40px
}

.steps__list__item {
    float: left;
    position: relative;
    z-index: 1
}

.steps__list__item:not(:last-child):after {
    content: '';
    position: absolute;
    top: 30%;
    left: -36px;
    z-index: 2;
    width: 2px;
    height: 75%;
    background: #dbdbdb
}

.steps__list__item:not(:last-child) {
    padding-bottom: 64px
}

.steps .image__container--steps {
    float: left;
    width: 30%
}

.steps .image__container--steps .img-responsive {
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%
}

.steps__item__description {
    float: right;
    padding: 16px 0 0 16px;
    width: 70%
}

.steps__item__description p {
    padding-bottom: 8px
}

.steps__item__description strong {
    font-weight: 500
}

.shape--square-steps {
    position: absolute;
    top: 10%;
    left: -48px;
    z-index: 2
}

.shape {
    width: 24px;
    height: 24px;
    color: #fafafa;
    line-height: 24px;
    border-radius: 8px;
    display: inline-block;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    text-align: center;
    background: linear-gradient(135deg, #891928 0, #be4c50 100%)
}

.shape--gray-two {
    color: #891928;
    background: #f3f3f3
}

.shape--xl {
    width: 48px;
    height: 48px;
    font-size: 24px;
    padding-top: 4px;
    line-height: 36px
}

.shape--circle {
    margin-right: 16px;
    text-align: center;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%
}

.arrow--lg {
    width: 18px;
    height: 18px
}

/* .arrow--primary-red {
    background: url(../images/icons/next-primary-red.svg) no-repeat
} */

#static-cover {
    top: 0;
    left: 0;
    z-index: 49;
    width: 100%;
    height: 100vh;
    position: fixed;
    background: rgba(0, 10, 18, 0.33)
}

#static-banner {
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 50;
    padding: 40px 0;
    position: fixed;
    background: #283238
}

#static-banner .row {
    margin: 0 !important;
    padding: 0 !important
}

#static-banner p {
    margin: 0 !important;
    padding: 0 !important
}

#close-modal {
    top: 0;
    right: 0;
    position: absolute;
    cursor: pointer
}

.floating-menu #menu-fab {
    z-index: 100 !important
}

.container--hero img {
    width: 100%
}

#botlers-messaging-button-iframe {
    display: none !important
}

/* @font-face {
    font-family: 'nmp';
    src: url('../fonts/nmp.woff2') format('woff2'), url('../fonts/nmp.woff') format('woff'), url('../fonts/nmp.ttf') format('truetype'), url('../fonts/nmp.eot') format('embedded-opentype');
    font-weight: normal;
    font-style: normal;
    font-display: block
} */

.accordion:after,
.arrow--menu,
.iconmp,
[class*=" icon-nmp-"],
[class^=icon-nmp-] {
    font-family: 'nmp' !important;
    speak: never;
    line-height: 1;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

@media (min-width:375px) {
    .steps__list__item:not(:last-child):after {
        top: 45%
    }

    .shape--square-steps {
        top: 25%
    }
}

@media (max-width:568px) {
    #static-banner {
        padding: 15px 0
    }
}

@media (min-width:768px) {

    .col-sm-1,
    .col-sm-10,
    .col-sm-11,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9 {
        float: left
    }

    .col-sm-1 {
        width: 8.333%
    }

    .col-sm-2 {
        width: 16.666%
    }

    .col-sm-3 {
        width: 25%
    }

    .col-sm-4 {
        width: 33.333%
    }

    .col-sm-5 {
        width: 41.666%
    }

    .col-sm-6 {
        width: 50%
    }

    .col-sm-7 {
        width: 58.333%
    }

    .col-sm-8 {
        width: 66.666%
    }

    .col-sm-9 {
        width: 75%
    }

    .col-sm-10 {
        width: 83.333%
    }

    .col-sm-11 {
        width: 91.666%
    }

    .col-sm-12 {
        width: 100%
    }

    .col-sm-offset-1 {
        margin-left: 8.333%
    }

    .col-sm-offset-2 {
        margin-left: 16.666%
    }

    .col-sm-offset-3 {
        margin-left: 25%
    }

    .col-sm-offset-4 {
        margin-left: 33.333%
    }

    .col-sm-offset-5 {
        margin-left: 41.666%
    }

    .col-sm-offset-6 {
        margin-left: 50%
    }

    .col-sm-offset-7 {
        margin-left: 58.333%
    }

    .col-sm-offset-8 {
        margin-left: 66.666%
    }

    .col-sm-offset-9 {
        margin-left: 75%
    }

    .col-sm-offset-10 {
        margin-left: 83.333%
    }

    .col-sm-offset-11 {
        margin-left: 91.666%
    }

    .hero__title {
        font-size: 3em;
        line-height: 72px
    }

    .hero__description {
        width: 85%
    }

    .container {
        padding-left: 15px;
        padding-right: 15px
    }

    .img-responsive--movil {
        display: none
    }

    .img-responsive--desktop {
        display: block
    }

    .header__logo .img-responsive--movil {
        display: block
    }

    .button--sm {
        width: auto;
        min-width: 128px
    }

    .button--lg {
        min-width: 191px;
        font-size: 18px;
        padding: 12px 24px;
        width: auto
    }

    .search-bar {
        top: 114px;
        padding: 48px 0
    }

    .floating-menu {
        right: 24px;
        bottom: 24px
    }

    h2 {
        font-size: 40px;
        line-height: 60px;
        padding-bottom: 24px
    }

    .section-padding {
        padding: 72px 0
    }

    .steps__description {
        line-height: 27px;
        font-size: 1.125em;
        padding-bottom: 98px
    }

    .steps__list {
        padding: 0;
        width: 100%;
        text-align: center
    }

    .steps__list__item {
        margin: 0;
        float: none;
        vertical-align: top;
        width: calc(98% / 4);
        padding: 72px 2.2em 0;
        display: inline-block
    }

    .steps__list__item:not(:last-child):after {
        top: 10px;
        z-index: 2;
        height: 2px;
        position: absolute;
        left: calc(50% + 24px);
        width: calc(100% - 48px)
    }

    .steps__list__item:not(:last-child) {
        padding-bottom: 0
    }

    .steps .image__container--steps {
        width: 100%;
        margin-bottom: 24px
    }

    .steps .image__container--steps .img-responsive {
        max-width: 120px
    }

    .steps__item__description {
        padding: 0;
        width: 100%;
        text-align: center
    }

    .steps__item__description p {
        padding-bottom: 32px
    }

    .shape--square-steps {
        top: 0;
        z-index: 2;
        position: absolute;
        left: calc(50% - 12px)
    }

    .promotions__slider-item {
        height: 560px
    }

    .promotions .container {
        text-align: left
    }

    .promotions__title {
        font-size: 3em;
        line-height: 72px
    }

    .promotions__description {
        width: 60%
    }

    .promotions .button--lg-promotions {
        width: 225px
    }

    .promotions__caption {
        position: absolute;
        bottom: 48px;
        left: 15px;
        z-index: 5;
        padding: 0;
        width: 68%
    }
}

@media (min-width:992px) {

    .col-md-1,
    .col-md-10,
    .col-md-11,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9 {
        float: left
    }

    .col-md-1 {
        width: 8.333%
    }

    .col-md-2 {
        width: 16.666%
    }

    .col-md-3 {
        width: 25%
    }

    .col-md-4 {
        width: 33.333%
    }

    .col-md-5 {
        width: 41.666%
    }

    .col-md-6 {
        width: 50%
    }

    .col-md-7 {
        width: 58.333%
    }

    .col-md-8 {
        width: 66.666%
    }

    .col-md-9 {
        width: 75%
    }

    .col-md-10 {
        width: 83.333%
    }

    .col-md-11 {
        width: 91.666%
    }

    .col-md-12 {
        width: 100%
    }

    .col-md-offset-0 {
        margin-left: 0
    }

    .col-md-offset-1 {
        margin-left: 8.333%
    }

    .col-md-offset-2 {
        margin-left: 16.666%
    }

    .col-md-offset-3 {
        margin-left: 25%
    }

    .col-md-offset-4 {
        margin-left: 33.333%
    }

    .col-md-offset-5 {
        margin-left: 41.666%
    }

    .col-md-offset-6 {
        margin-left: 50%
    }

    .col-md-offset-7 {
        margin-left: 58.333%
    }

    .col-md-offset-8 {
        margin-left: 66.666%
    }

    .col-md-offset-9 {
        margin-left: 75%
    }

    .col-md-offset-10 {
        margin-left: 83.333%
    }

    .col-md-offset-11 {
        margin-left: 91.666%
    }
}

@media (min-width:1024px) {
    .header {
        padding: 0
    }

    .header .container {
        padding: 16px 15px
    }

    .header .sub-menu {
        left: 0;
        top: 57px;
        z-index: 2;
        width: 100%;
        display: none;
        font-size: 14px;
        background: #FFF;
        line-height: 21px;
        position: absolute;
        padding: 31px 5px 0
    }

    .header .header__phone {
        background: #283238;
        display: block;
        font-size: 0.75em;
        line-height: 5px;
        padding: 10px;
        text-transform: uppercase;
        text-align: center
    }

    .header .header__phone a {
        color: #FFF
    }

    .header__logo {
        float: left;
        width: auto
    }

    .header__logo .img-responsive {
        display: none
    }

    .header__logo .img-responsive--desktop {
        display: block
    }

    .header__menu {
        float: left;
        display: block !important;
        font-size: 0.875em;
        line-height: 21px;
        padding-top: 16px;
        padding-left: 25px
    }

    .header__menu li {
        padding: 0 10px 20px;
        display: inline-block
    }

    .header__menu li a {
        color: #283238
    }

    .header__menu-right {
        float: right;
        display: block;
        padding-top: 6px
    }

    .header__menu-right li {
        margin-left: 16px;
        display: inline-block
    }

    .header__menu-right .button--sm {
        width: 140px
    }

    .header .nav-landing .container {
        padding: 0
    }

    .nav-landing__menu {
        font-size: 0.875em
    }

    .hamburger,
    .icon--search-movil,
    .menu__mobile,
    .nav-mobile {
        display: none !important
    }

    .layer {
        background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.4)), to(transparent));
        background-image: linear-gradient(to right, rgba(0, 0, 0, 0.4), transparent)
    }

    .section-title {
        font-size: 2.5em;
        font-weight: 500;
        line-height: 60px;
        padding-bottom: 24px
    }
}

@media (min-width:1200px) {

    .col-lg-1,
    .col-lg-10,
    .col-lg-11,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9 {
        float: left
    }

    .col-lg-1 {
        width: 8.333%
    }

    .col-lg-2 {
        width: 16.666%
    }

    .col-lg-3 {
        width: 25%
    }

    .col-lg-4 {
        width: 33.333%
    }

    .col-lg-5 {
        width: 41.666%
    }

    .col-lg-6 {
        width: 50%
    }

    .col-lg-7 {
        width: 58.333%
    }

    .col-lg-8 {
        width: 66.666%
    }

    .col-lg-9 {
        width: 75%
    }

    .col-lg-10 {
        width: 83.333%
    }

    .col-lg-11 {
        width: 91.666%
    }

    .col-lg-12 {
        width: 100%
    }

    .col-lg-offset-0 {
        margin-left: 0
    }

    .col-lg-offset-1 {
        margin-left: 8.333%
    }

    .col-lg-offset-2 {
        margin-left: 16.666%
    }

    .col-lg-offset-3 {
        margin-left: 25%
    }

    .col-lg-offset-4 {
        margin-left: 33.333%
    }

    .col-lg-offset-5 {
        margin-left: 41.666%
    }

    .col-lg-offset-6 {
        margin-left: 50%
    }

    .col-lg-offset-7 {
        margin-left: 58.333%
    }

    .col-lg-offset-8 {
        margin-left: 66.666%
    }

    .col-lg-offset-9 {
        margin-left: 75%
    }

    .col-lg-offset-10 {
        margin-left: 83.333%
    }

    .col-lg-offset-11 {
        margin-left: 91.666%
    }

    .container {
        width: 1200px;
        padding-left: 0;
        padding-right: 0;
        max-width: 1200px
    }

    .header {
        padding: 0
    }

    .header .container {
        padding: 16px 0
    }
}

.rll-youtube-player {
    position: relative;
    padding-bottom: 56.23%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
}

.rll-youtube-player iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: 0 0
}

.rll-youtube-player img {
    bottom: 0;
    display: block;
    left: 0;
    margin: auto;
    max-width: 100%;
    width: 100%;
    position: absolute;
    right: 0;
    top: 0;
    border: none;
    height: auto;
    cursor: pointer;
    -webkit-transition: .4s all;
    -moz-transition: .4s all;
    transition: .4s all
}

.rll-youtube-player img:hover {
    -webkit-filter: brightness(75%)
}

.rll-youtube-player .play {
    height: 72px;
    width: 72px;
    left: 50%;
    top: 50%;
    margin-left: -36px;
    margin-top: -36px;
    position: absolute;
    background: url(https://www.montepiedad.com.mx/wp-content/plugins/rocket-lazy-load/assets/img/youtube.png) no-repeat;
    cursor: pointer
}